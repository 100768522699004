import axios from "@/util/axios";

export default {
    login(data) {
        return axios.fetch({
            method: "post",
            url: "admins/login",
            data
        });
    },
    overview(data) {
        return axios.fetch({
            method: "post",
            url: "auth/admins/overview",
            data
        });
    },
}
