<template>
    <div class="bg">
        <div class="loginBlock">
            <img src="../assets/logo.svg" alt="">
            <div class="input-area">
                <label for="account">帳號</label>
                <input type="text" name="account" id="account" v-model="account">
            </div>
            <div class="input-area">
                <label for="password">密碼</label>
                <input type="password" name="password" id="password" v-model="password">
            </div>
            <button class="login-btn" @click="login">登入</button>
        </div>
    </div>
</template>

<script>
import adminApi from "@/apis/admin";

export default {
    name: 'index',
    data() {
        return {
            account: '',
            password: '',
            rememberMe: false,
            check: true
        }
    },
    created() {
        if(this.$auth.isLogin()){
            this.$router.push({name: "202208dashboard"});
        }
    },
    mounted() {
        if (window.localStorage.getItem('account')) {
            this.account = window.localStorage.getItem('account')
        }
    },
    methods: {
        login() {
            let data = {account: this.account, password: this.password}
            adminApi.login(data).then((json) => {
                this.$auth.setAdminAccount(this.account)
                this.$auth.setAdminToken(json.data);
                this.$public.showNotify("登入成功", true);
                this.$router.push({name: "202208dashboard"});
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.bg {
    background: #27327b;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loginBlock {
        width: 400px;
        height: 200px;
        transform: translateY(-50px);

        .input-area {
            width: 100%;
            margin-top: 15px;
            display: inline-flex;
            justify-content: space-around;
            align-items: center;

            label {
                color: white;
                font-size: 18px;
                font-weight: bold;
                width: 20%;
                user-select: none;
            }

            input {
                width: 80%;
                padding: 5px;
                border-radius: 5px;
            }
        }

        .check-box-area {
            width: 100%;
            height: 30px;
            margin-top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            label {
                margin-left: 15px;
                color: white;
                font-size: 18px;
                font-weight: bold;
                user-select: none;
            }
        }

        .login-btn {
            background-color: #5386c0;
            color: #fff;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 15px 5px;
            cursor: pointer;
            margin: 16px auto;
            user-select: none;
        }


        img {
            width: 100%;
        }
    }
}
</style>
